<template>
  <!-- 规模管理 -->
  <div class="outer-page">
    <x-table
      :no-data-text="CA('scale_check') ? '暂无数据' : '暂无数据查询权限'"
      :columns="table.columns"
      :data="table.data"
      :loading="table.loading"
      :config="config"
      @search="search"
      @add="add"
      @page-change="pageChange"
      @page-size-change="pageSizeChange"
    ></x-table>
    <Modal
      v-model="modal.show"
      :title="modal.title"
      :width="450"
      @on-visible-change="modalShow"
    >
      <Form :label-width="100" ref="form" :model="form" :rules="rules">
        <FormItem label="规模类型" prop="type">
          <Select
            clearable
            style="width: 240px"
            placeholder="请选择规模类型"
            v-model="form.type"
          >
            <Option :value="1">养殖场</Option>
            <Option :value="2">养殖区</Option>
          </Select>
        </FormItem>
        <FormItem label="规模等级" prop="levels">
          <Select
            clearable
            style="width: 240px"
            placeholder="请选择规模等级"
            v-model="form.levels"
          >
            <Option :value="1">一级</Option>
            <Option :value="2">二级</Option>
          </Select>
        </FormItem>
        <FormItem label="产品" prop="products">
          <Select
            clearable
            style="width: 240px"
            placeholder="请选择产品"
            v-model="form.products"
          >
            <Option
              v-for="(item, index) in productList"
              :key="index"
              :value="item.id + '/' + item.catId"
              >{{ item.name }}</Option
            >
          </Select>
        </FormItem>
        <FormItem label="描述">
          <Input
            clearable
            style="width: 240px"
            placeholder="请填写描述"
            type="textarea"
            v-model="form.descs"
          ></Input>
        </FormItem>
      </Form>
      <p slot="footer" style="text-align: center">
        <Button type="primary" @click="submit">提交</Button>
        <Button @click="() => (modal.show = false)">取消</Button>
      </p>
    </Modal>
  </div>
</template>

<script>
export default {
  name: "",
  components: {},
  data() {
    return {
      table: {
        columns: [
          {
            title: "规模类型",
            minWidth: 120,
            render: (h, { row }) => {
              return <span>{row.type == 1 ? "养殖场" : "养殖区"}</span>;
            },
          },
          {
            title: "规模等级",
            align: "center",
            width: 160,
            render: (h, { row }) => {
              return <span>{row.levels == 1 ? "一级" : "二级"}</span>;
            },
          },
          {
            title: "品种",
            align: "center",
            key: "catName",
          },
          {
            title: "产品",
            key: "productName",
          },
          {
            title: "描述",
            key: "descs",
          },
          {
            title: "操作",
            width: 200,
            render: (h, { row }) => {
              return (
                <div>
                  {this.CA("scale_update") && (
                    <a
                      style="margin-right: 10px"
                      onClick={() => this.edit(row)}
                    >
                      编辑
                    </a>
                  )}
                  {this.CA("scale_delete") && (
                    <Poptip
                      confirm
                      transfer
                      title="确定删除吗?"
                      on-on-ok={() => this.delete(row.id)}
                    >
                      <a>删除</a>
                    </Poptip>
                  )}
                </div>
              );
            },
          },
        ],
        data: [],
        loading: false,
      },
      config: {
        filter: {
          add: {
            addBtnName: "新增规模",
            ca: "scale_add",
          },
          width: 200,
          filterBox: [
            {
              conditionName: "产品",
              component: "select",
              field: "productId",
              defaultValue: "",
              data: [],
              parameterField: "id",
              showField: "name",
            },
          ],
        },
        page: {
          pageNo: 1,
          pageSize: 10,
          total: 0,
        },
      },

      modal: {
        show: false,
        title: "",
        submitLoading: false,
      },
      form: {
        id: "",
        type: "",
        levels: "",
        catId: "",
        productId: "",
        descs: "",
        products: "",
      },
      rules: {
        type: [{ required: true, message: "请选择规模类型" }],
        levels: [{ required: true, message: "请选择规模等级" }],
        products: [{ required: true, message: "请选择产品" }],
      },
      search_data: {},
      //产品列表
      productList: [],
    };
  },
  methods: {
    search(data) {
      this.search_data = data;
      this.config.page.pageNo = 1;
      this.getList();
    },

    add() {
      this.modal = {
        show: true,
        title: "新增规模信息",
        submitLoading: false,
      };
    },

    pageChange(pageNo) {
      this.config.page.pageNo = pageNo;
      this.getList();
    },
    pageSizeChange(pageSize) {
      this.config.page.pageSize = pageSize
      this.getList()
    },

    getList() {
      if (!this.CA("scale_check")) return;
      this.table.loading = true;
      this.$post(this.$api.SCALE_MANAGE.LIST, {
        pageNo: this.config.page.pageNo,
        pageSize: this.config.page.pageSize,
        ...this.search_data,
      })
        .then((res) => {
          this.table.data = res.list;
          this.config.page.total = +res.total;
        })
        .finally(() => {
          this.table.loading = false;
        });
    },

    //产品列表
    getProduct() {
      this.$post(this.$api.PRODUCT_INFO.LIST, {
        type: 2,
      }).then((res) => {
        this.productList = res.list;
        this.config.filter.filterBox[0].data = res.list;
      });
    },
    edit(row) {
      for (let key in this.form) {
        this.form[key] = row[key];
      }
      this.form.products = row.productId + "/" + row.catId;
      this.modal = {
        show: true,
        title: "编辑规模信息",
        submitLoading: false,
      };
    },
    delete(id) {
      this.$post(this.$api.SCALE_MANAGE.DELETE, {
        id,
      }).then(() => {
        this.$Message.success("删除成功！");
        this.getList();
      });
    },
    submit() {
      let params = this.form;
      if (this.form.products) {
        params.productId = this.form.products.split("/")[0];
        params.catId = this.form.products.split("/")[1];
      }
      let url;
      if (params.id) {
        url = this.$api.SCALE_MANAGE.UPDATE;
      } else {
        url = this.$api.SCALE_MANAGE.ADD;
      }
      this.$refs.form.validate().then((res) => {
        if (!res) return;
        this.$post(url, params)
          .then(() => {
            this.$Message.success(params.id ? "编辑成功！" : "添加成功！");
            this.getList();
            this.modal.show = false;
          })
          .catch((e) => {
            console.log(e);
          });
      });
    },

    modalShow(visible) {
      if (visible) return;
      this.form = {
        id: "",
        type: "",
        levels: "",
        catId: "",
        productId: "",
        descs: "",
      };
      this.$refs.form.resetFields();
    },
  },
  mounted() {
    this.getList();
    this.getProduct();
  },
};
</script>

<style lang="less" scoped>
.outer-page {
  width: 100%;
  height: 100%;
}
</style>